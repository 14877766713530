<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text class="text-center">
                    <v-icon size="200" color="primary lighten-4">mdi-monitor-dashboard</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "TiHome",
};
</script>